<template>
  <div>
    <template v-if="isLogin">
      <template v-if="isPro">
        <template v-if="isLifetime">
          <template v-if="plansType === 'LifeTime'">
            <b><i class="fas fa-check" /> {{ $t("plans.currentPlan") }}</b>
            <b style="color: orange;" class="timedLimited">
              Forever
            </b>
          </template>
        </template>
        <template v-else>
          <template v-if="plansType === 'Year'">
            <b>
              <i class="fas fa-check" /> {{ $t("plans.currentPlan") }}
              <el-button
                class="upgradeButton"
                @click="upgradePlan"
                type="warning"
                size="mini"
                style="width: auto;"
              >
                {{ $t("plans.extendPlan") }}
              </el-button>
            </b>
            <span class="timedLimited">
              {{ `${$t("plans.planDuration")}${proTimeLimited} (GMT+8)` }}
            </span>
          </template>
          <template v-if="plansType === 'LifeTime'">
            <el-button class="upgradeButton" @click="upgradePlan">
              {{ $t("plans.upgrade") }}
            </el-button>
          </template>
        </template>
      </template>
      <el-button
        v-else-if="plansType !== 'free'"
        class="upgradeButton"
        @click="upgradePlan"
        type="warning"
      >
        {{ $t("plans.upgrade") }}
      </el-button>
    </template>
    <router-link v-else :to="{ name: 'Login' }">
      <el-button v-if="plansType === 'free'" class="upgradeButton">
        {{ $t("plans.select") }}
      </el-button>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    isPro: {
      type: Boolean,
      default: false
    },
    isLifetime: {
      type: Boolean,
      default: false
    },
    plansType: {
      type: String,
      default: null
    },
    proTimeLimited: {
      type: String,
      default: null
    }
  },
  methods: {
    upgradePlan() {
      this.$emit("upgradePlan");
    }
  }
};
</script>

<style scoped lang="scss">
.timedLimited {
  color: #00000077;
}
.upgradeButton {
  background: orange;
  color: #ffffff;
  width: 120px;
}
</style>
